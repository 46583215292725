import { SVGName } from '~/ui/components/SVG'
import { CustomImage } from './CustomImage'
import { Link } from './Link'
import { Model, resource } from './Model'
import { Project } from './Project'
import { Targeting } from './Targeting'
import { LocalizedString } from './Translations'
import { Ref } from './types'

@resource<Page>('pages', {
  icon:    page => page == null ? 'page' : `${page.type}-page` as SVGName,
  caption: page => page.title,
  appLink: page => page.slug === '' ? '//info' : `//page/${page.slug}`,
})
export class Page extends Model {

  public project!: Ref<Project>

  public type!: PageType

  public title!: LocalizedString
  public slug!:  string

  public targeting!: Targeting

  public locked!: boolean

  //------
  // Menu

  public layout!:  MenuLayout
  public items!:   MenuPageItem[]
  public caption!: LocalizedString | null

  //------
  // Index

  public itemType!:    IndexItemType
  public filter!:      AnyObject
  public sort!:        string | null
  public viewOptions!: IndexViewOptions

  //------
  // Content

  public body!: LocalizedString

  //------
  // Programme

  public date!: Date

}

export type PageType = 'content' | 'menu' | 'index' | 'programme'
export const PageType: {
  all: PageType[]
} = {
  all: ['content', 'menu', 'index', 'programme'],
}

export type MenuLayout = 'list' | 'grid'

export interface MenuPageItem {
  image:   CustomImage | null
  caption: LocalizedString
  detail:  LocalizedString | null
  link:    Link
}

export type IndexItemType = 'Participant'
export const IndexItemType: {
  all: IndexItemType[]
} = {
  all: ['Participant'],
}

export type IndexFilter = IndexResourceFilter

export interface IndexResourceFilter {
  type:         'resource'
  name:         string
  resourceType: string
}

export interface IndexSort {
  name:        string
  directions?: Array<1 |-1>
}

export interface IndexViewOptions {
  [name: string]: boolean
}