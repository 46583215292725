import React from 'react'
import { useHistory } from 'react-router-dom'
import { upperFirst } from 'lodash'
import { Page, PageType } from '~/models'
import { dataStore } from '~/stores'
import { memo } from '~/ui/component'
import {
  Empty,
  HBox,
  panelBorderRadius,
  PopupMenu,
  PopupMenuItem,
  PushButton,
  VBox,
} from '~/ui/components'
import { SVGName } from '~/ui/components/SVG'
import { SubmitResult } from '~/ui/form'
import { useBoolean } from '~/ui/hooks'
import { useModelEndpoint } from '~/ui/hooks/data'
import { AppLayoutConfig, Breadcrumb } from '~/ui/layouts/app'
import { useResourceTranslation } from '~/ui/resources'
import { createUseStyles, layout } from '~/ui/styling'
import PageForm from '../create/PageForm'
import PagePanel, { pagePanelWidth } from '../PagePanel'
import PagesList from './PagesList'

export interface Props {
  id: string | null
}

const PagesListContainer = memo('PagesListContainer', (props: Props) => {

  const {id} = props
  const {t, plural} = useResourceTranslation('pages')

  const history  = useHistory()
  const endpoint = useModelEndpoint(Page)

  //------
  // Breadcrumbs

  const page = id == null ? null : dataStore.get(Page, id)
  const breadcrumbs = React.useMemo(() => {
    const breadcrumbs: Breadcrumb[] = []

    breadcrumbs.push({
      icon:    'page',
      caption: upperFirst(plural()),
      href:    '/pages',
    })

    if (page != null) {
      breadcrumbs.push({
        icon:    `${page.type}-page` as SVGName,
        caption: page.title,
        href:    `/pages/${page.slug}`,
      })
    }

    return breadcrumbs
  }, [page, plural])

  //------
  // Page creation

  const [createFormOpen, openCreateForm, closeCreateForm] = useBoolean()
  const [createType, setCreateType] = React.useState<PageType>('content')

  const create = React.useCallback((type: PageType) => {
    setCreateType(type)
    openCreateForm()
  }, [openCreateForm])

  const createMenuItems = React.useMemo((): PopupMenuItem<PageType>[] => [
    {value: 'content', icon: 'content-page', ...t('types.content')},
    {section: '-'},
    {value: 'menu', icon: 'menu-page', ...t('types.menu')},
    {value: 'index', icon: 'index-page', ...t('types.index')},
  ], [t])

  const navigateOnSuccess = React.useCallback((result: SubmitResult) => {
    if (result.status !== 'ok' || result.data?.id == null) { return }
    history.push(`/pages/-/${result.data.id}${document.location.search}`)
    endpoint.fetch()
  }, [endpoint, history])

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <HBox flex align='stretch' gap={layout.padding.l}>
        <AppLayoutConfig
          configKey={`pages.list.${id ?? 'none'}`}
          breadcrumbs={breadcrumbs}
          ActionsComponent={renderActions}
        />

        <VBox flex>
          <PagesList
            endpoint={endpoint}
            selectedID={id}
          />
        </VBox>
        <VBox>
          {id == null ? (
            <Empty
              {...t('select_page')}
              classNames={$.empty}
              flex
            />
          ) : (
            <PagePanel
              id={id}
              root={true}
            />
          )}
        </VBox>

        <PageForm
          open={createFormOpen}
          requestClose={closeCreateForm}
          type={createType}
          afterSubmit={navigateOnSuccess}
        />
      </HBox>
    )
  }

  const renderActions = React.useCallback(() => {
    return (
      <PopupMenu items={createMenuItems} onValueSelect={create}>
        {toggle => (
          <PushButton
            icon='plus'
            caption={t('new')}
            onTap={toggle}
            small
          />
        )}
      </PopupMenu>
    )
  }, [create, createMenuItems, t])

  return render()

})

export default PagesListContainer

const useStyles = createUseStyles(theme => ({
  empty: {
    width:        pagePanelWidth,
    borderRadius: panelBorderRadius(theme),
    border:       [1, 'solid', theme.fg.dimmer],
  },
}))